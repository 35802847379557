body {
  font-family: "Open Sans", sans-serif;
}

.modalContainer .ant-modal-header {
  min-height: 50px;
}
.map-popover .ant-popover-inner-content {
  padding: 24px;
  width: 260px;
}

.map-popover .ant-popover-inner {
  border-radius: 10px;
}

.map-popover .ant-popover-content {
  margin-bottom: 3px;
}
.map-popover .ant-popover-arrow {
  width: 25px;
  height: 25px;
}

.map-popover .ant-popover-arrow-content {
  width: 25px;
  height: 25px;
}

/* header drawer css start */
.header-drawer {
  z-index: 1500 !important;
}

.header-drawer svg:hover {
  opacity: 0.8;
}
.header-drawer .ant-drawer-content-wrapper {
  width: 270px !important;
}

.header-drawer .ant-drawer-body {
  padding: 47px 35px 29px 35px;
}

.header-drawer .ant-drawer-close {
  padding: 0px;
  margin-right: 40px !important;
  padding-right: 0px !important;
  top: 51px;
}

.header-drawer .ant-divider {
  margin: 32px 0px;
  border-color: #0b1f301a;
}

.header-drawer .links a {
  display: block;
  margin-bottom: 34px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #191a19;
  letter-spacing: 0.4px;
}

.header-drawer .links .active,
.header-drawer .links a:hover {
  color: #ed4747;
}

.header-drawer .links .active {
  font-weight: 700;
}

.header-drawer h4 {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 13px;
  font-weight: 400;
}

.header-drawer .ant-select-selector {
  border-radius: 10px;
}

.header-drawer .ant-select-selector span {
  font-size: 16px;
}

.header-drawer .ant-select-selection-item {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  margin: auto;
}

.header-drawer .ant-select-selector {
  height: 46px !important;
  border-radius: 10px !important;
  width: 113px !important;
}

.header-drawer .ant-select {
  margin-bottom: 32px;
}

.header-drawer .ant-select-arrow {
  right: 16px;
}

.header-drawer .contact-button {
  height: 46px;
  width: 150px;
  padding: 0px;
  margin-bottom: 31px;
}

.header-drawer .follow > a:first-of-type {
  margin-right: 23px;
}

/* header drawer styling ends */

@media (max-width: 875px) {
  .map-popover {
    display: none;
  }
}

/* sider */

.side-menu {
  border-right: none;
}

.side-menu .ant-menu-title-content {
  display: flex;
  align-items: center;
}

.side-menu .ant-menu-title-content > svg {
  margin-right: 15px;
}

.side-menu .ant-menu-item {
  background: #fff !important;
  margin-top: 0px;
  padding-left: 16px !important;
  margin-bottom: 0px !important ;
  border-left: 2px solid #cbcdcb;
  padding-top: 23px;
  padding-bottom: 16px;
  color: #7c837c;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}

.side-menu .ant-menu-item:hover {
  color: #121212;
}

.side-menu .ant-menu-item:hover .ant-menu-title-content > svg > * {
  fill: #121212;
}

.side-menu .ant-menu-item-selected {
  background: #fff;
  border-left: 4px solid #ed4747;
  color: #16222a;
}

.drawer-dropdown {
  z-index: 1500 !important;
}

.side-menu .ant-menu-item-selected .ant-menu-title-content > svg > * {
  fill: #16222a;
}
.side-menu .ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}

.react-multiple-carousel__arrow {
  display: none;
}

.article-select {
  z-index: 100 !important;
}

.ant-image-preview-operations-operation:not(:nth-child(-n + 3)) {
  display: none;
}
